// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import HorizontalLine from '../atoms/HorizontalLine';

type Props = {
  text: string,
};

const SubSectionHeader = ({ text }: Props) => (
  <Root>
    <Header>
      <Label>{text}</Label>
    </Header>
    <RuleLayout>
      <HorizontalLine shape="crown" />
    </RuleLayout>
  </Root>
);

const Root = styled.div({
  padding: '1rem 0',
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const Label = styled.h2((props) => ({
  color: props.theme.colors.primary,
  fontSize: '1.2rem',
  fontWeight: 'normal',
  marginBottom: 0,
  [props.theme.mediaQuery.minTablet]: {
    fontSize: '1.5rem',
  },
}));

const RuleLayout = styled.div((props) => ({
  margin: '0 auto',
  padding: '0.5rem 0 0',
  width: '80%',
  [props.theme.mediaQuery.minTablet]: {
    width: 450,
  },
}));

export default SubSectionHeader;
