// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Section from '../molecules/Section';
import SubSectionHeader from '../molecules/SubSectionHeader'
import Banner from '../atoms/Banner';

type Props = {
  title: String,
  details: String[],
  links: Array,
};

const InformationDetails = ({ title, details, links }: Props) => (
  <Section title={title}>
    <DetailList>
      {details.map((detail, index) => (
        <Detail key={index.toString()}>{detail}</Detail>
      ))}
    </DetailList>
    <SubSectionHeader text="リンク" />
    <LinkList>
      {links.map((link, index) => (
        <LinkItem key={index.toString}>
          <Anchor href={link.address} rel="noreferrer" target="_blank">
            {link.title} 様
          </Anchor>
        </LinkItem>
      ))}
    </LinkList>
    <SubSectionHeader text="バナー" />
    <BannerInfo>
      当サイトへのリンクには、
      <br />
      以下のバナーをダウンロードしてご利用ください。
      <BannerWrap>
        <Banner />
      </BannerWrap>
    </BannerInfo>
  </Section>
);

const DetailList = styled.div({
  marginTop: '2rem',
  marginBottom: '2rem',
});

const Detail = styled.div((props) => ({
  color: props.theme.colors.primary,
  margin: '0.8rem 0',
  textAlign: 'center',
}));

const BannerInfo = styled.div((props) => ({
  color: props.theme.colors.primary,
  margin: '-1 auto',
  textAlign: 'center',
}));

const BannerWrap = styled.div((props) => ({
  color: props.theme.colors.primary,
  margin: '1rem auto',
  maxWidth: 320,
}));

const LinkList = styled.div({
  marginTop: '1rem',
  marginBottom: '2rem',
});

const LinkItem = styled.div({
  textAlign: 'center',
});

const Anchor = styled.a((props) => ({
  color: props.theme.colors.primary,
}));

export default InformationDetails;
