import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Template from '../templates/InformationPage';

const query = graphql`
  query {
    pageYaml(page: { eq: "information" }) {
      hero
      title
      details
      links {
        items {
          title
          address
        }
      }
    }
  }
`;

const InformationPage = () => (
  <StaticQuery query={query} render={(data) => <Template pageData={data.pageYaml} />} />
);

export default InformationPage;
