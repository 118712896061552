// @flow strict
import React from 'react';

import Hero from '../atoms/Hero';
import InformationDetails from '../organisms/InformationDetails';

type Props = {
  pageData: {
    hero: String,
    title: String,
    details: String[],
    links: {
      items: {
        title: string,
        address: string,
      }[],
    },
  },
};

const IndexPage = ({ pageData }: Props) => (
  <div>
    <Hero color={pageData.hero} />
    <InformationDetails title={pageData.title} details={pageData.details} links={pageData.links.items} />
  </div>
);

export default IndexPage;
