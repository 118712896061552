// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const query = graphql`
  query {
    file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Banner = () => (
  <StaticQuery
    query={query}
    render={(data) => <BannerImage fluid={data.file.childImageSharp.fluid} alt="Banner" />}
  />
);

const BannerImage = styled(Img)({
  maxWidth: 320,
});

export default Banner;
